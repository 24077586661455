<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Legal Board Services
      Author: Enkompass
    Author URL: https://www.enkompass.net
========================================================================================== -->


<template>
    <div class="h-screen flex w-full bg-img">
        <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
                            <div class="p-8">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Update your password</h4>
                                    <p>Please update your password to continue.</p>
                                </div>

                                <vs-input
                                  ref="password"
                                  type="password"
                                  data-vv-validate-on="blur"
                                  v-validate="'required|min:1'"
                                  name="password"
                                  label-placeholder="Password"
                                  placeholder="Password"
                                  v-model="password"
                                  class="w-full mt-6" />
                                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                                <vs-input
                                  type="password"
                                  v-validate="'min:1|confirmed:password'"
                                  data-vv-validate-on="blur"
                                  data-vv-as="password"
                                  name="confirm_password"
                                  label-placeholder="Confirm Password"
                                  placeholder="Confirm Password"
                                  v-model="confirm_password"
                                  class="w-full mt-6" />
                                <span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>
                                <vs-button class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-3 md:mb-8" :disabled="!validateForm" @click="loginAmplify">Continue</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
      password: '',
      confirm_password: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.password !== '' && this.confirm_password !== '' && this.password === this.confirm_password
    }
  },
  methods: {
    checkLogin () {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning'
        })

        return false
      }
      return true
    },
    loginAmplify () {

      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return
      const payload = {
        userDetails: {
          password: this.password,
          confirmPassword: this.confirm_password
        },
        notify: this.$vs.notify
      }

      // Loading
      this.$vs.loading()

      this.$store.dispatch('auth/loginAmplify', payload)
      .then(resp => {
        this.$vs.loading.close()
        if(resp.message){
          this.$vs.notify({
            title: 'Success',
            text: resp.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        }
      })
      .catch(error => {
        this.password = '';
        this.confirm_password = ''
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })
    }
  }
}
</script>
